export const path = {
  auth:'/auth',
  landing: '/landing',
  login: '/login',
  signup: '/signup',
  profile: '/profile',
  resetPassword: '/reset-password',
  confirmEmail: '/confirm-email',
  dashboard: '/dashboard',
  form: '/form',
  legal: '/legal'
}
