import React, { Suspense, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import { actions } from 'slices/app.slice'
import { path } from 'utils/const'
import Fallback from 'components/Fallback'
import Spinner from 'components/Spinner'

const Auth = React.lazy(() => import('./pages/auth'))
// const Dashboard = React.lazy(() => import('./pages/dashboard'))
const Form = React.lazy(() => import('./pages/form'))
const Legal = React.lazy(() => import('./pages/legal'))
const Landing = React.lazy(() => import('./pages/landing'))

function Router() {
  const dispatch = useDispatch()
  const { checked, loggedIn } = useSelector((state) => state.app)
  
  useEffect(() => {
    dispatch(actions.authenticate())
  }, [])

  // if (!checked) {
  //   return (
  //     <div className="app-loader-container">
  //       <Spinner size="4rem" color="white" isLoading />
  //     </div>
  //   )
  // }

  if (!checked) {

        return (
      <div className="app-loader-container">
        <Spinner size="4rem" color="white" isLoading />
      </div>
    )
  }


  return (  
    <BrowserRouter>
      <Suspense fallback={<Fallback />}>

        {!loggedIn ? (
          <Switch>
            <Route path={path.legal} exact>
              <Legal />
            </Route>
            <Route path="/landing" exact>
              <Landing />
            </Route>
            <Route path="/">
              <Auth /> {/* Aquí Auth manejará las sub-rutas */}
            </Route>
            <Route path="/" exact>
              <Redirect to="/" /> {/* Redirige a la página de landing como la predeterminada */}
            </Route>
          </Switch>
        ) :  (
          <>
            <Route path={path.legal} exact>
              <Legal />
            </Route>
            <Route path="/landing" exact>
              <Landing />
            </Route>
            {/* <Route path={path.dashboard} exact>
              <Dashboard />
            </Route> */}
            <Route path={path.form} exact>
              <Form />
            </Route>
            <Redirect to={path.form} />
          </>
        )}
      </Suspense>
    </BrowserRouter>
  )
}

export default Router
